<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    :class="classname"
  >
    <path d="M6.25 8.12449C6.51841 8.48332 6.86085 8.78023 7.25409 8.99508C7.64734 9.20993 8.08219 9.33769 8.52916 9.3697C8.97612 9.40171 9.42474 9.33722 9.84459 9.1806C10.2644 9.02399 10.6457 8.77891 10.9625 8.46199L12.8375 6.58699C13.4067 5.99761 13.7217 5.20823 13.7146 4.38886C13.7075 3.5695 13.3788 2.78571 12.7994 2.20631C12.22 1.62691 11.4362 1.29825 10.6169 1.29113C9.79751 1.28401 9.00813 1.599 8.41875 2.16824L7.34375 3.23699" stroke="#0075FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.75012 6.87429C8.48172 6.51546 8.13928 6.21855 7.74603 6.0037C7.35279 5.78885 6.91793 5.66109 6.47097 5.62908C6.024 5.59707 5.57538 5.66156 5.15553 5.81817C4.73568 5.97479 4.35443 6.21987 4.03762 6.53679L2.16262 8.41179C1.59338 9.00117 1.2784 9.79055 1.28552 10.6099C1.29264 11.4293 1.62129 12.2131 2.20069 12.7925C2.78009 13.3719 3.56388 13.7005 4.38325 13.7076C5.20261 13.7148 5.99199 13.3998 6.58137 12.8305L7.65012 11.7618" stroke="#0075FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
