<template lang="html">
  <Modal closePosition="visible">
    <div class="relative px-6 pt-6 pb-16 root">
      <div
        v-if="states[currentState]"
        v-html="states[currentState]"
        class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg -top-14"
        :class="[currentState < 500 ? 'bg-green' : 'bg-red']"
      />

      <!-- link based sharing here -->
      <div class="rounded-bl-lg rounded-br-lg absolute bottom-0 left-0 right-0 bg-primary-200 flex items-center justify-center py-1.5">
        <div class="flex items-center cursor-pointer" @click="copyLink">
          <LinkIcon class="mr-2.5 h-4 w-4 text-transparent" />
          <div class="w-20 text-xs text-blue">{{copyLinkText}}</div>
        </div>

        <v-popover
          placement="bottom"
          popoverClass="popover-sm"
          popoverArrowClass="hidden"
        >
          <div class="w-80 text-sm text-primary px-4 py-2.25 bg-white border border-white rounded flex items-center justify-between cursor-pointer">
            <div class="text-sm">{{publicPermissions[publicAccess]}}</div>
            <ChevronDown class="w-3 h-3 dropdownCaret text-primary-500"/>
          </div>

          <template slot="popover">
            <div class="p-1 w-80">
              <div
                v-for="(value,key) in Object.keys(publicPermissions)"
                :key="key"
                :value="key"
                @click="publicAccess=value;setPublicAccess(value)"
                v-close-popover
                class="relative pl-8 py-2.5 pr-2.5 mb-2 last:mb-0 text-sm text-primary cursor-pointer rounded hover:bg-primary-200"
              >
                {{publicPermissions[value]}}
                <CheckIcon v-if="publicAccess == value" class="h-5 w-5 absolute top-2 left-1.5"/>
              </div>
            </div>
          </template>
        </v-popover>
      </div>

      <h2 class="text-2xl font-semibold font-h text-primary">
        Invite Collaborators
      </h2>

      <input
        placeholder="E-mail"
        type="text"
        @keyup.enter="inviteEmail"
        v-model="email"
        class="mt-2 w-full resize-none rounded-lg bg-primary-100 placeholder-primary-500 border border-primary-200 focus:border-primary-200 focus:ring-2 focus:ring-primary py-2.5 px-4 text-primary text-sm"
      >

      <div class="mt-2.5 flex justify-end">
        <Button
          classname="pr-1.5"
          size="small"
          @click="inviteEmail"
         >
          Invite <ChevronRight classname="h-3 w-3 ml-0.75"/>
        </Button>
      </div>

      <div v-if="Object.keys(team).length>0" class="pt-2 pr-2 mt-3 overflow-y-auto border-t-2 border-primary-200 max-h-60">
        <table class="w-full">
          <tr class="font-semibold font-h text-primary">
            <td class="w-7/12 text-lg">Invited Members</td>
            <td class="w-5/12"/>
          </tr>
          <tr v-for="(person,key) in team" :key="key" >
            <td class="h-10 text-xs text-primary">{{person.email}}</td>
            <td v-if="key==='owner'" class="text-xs text-primary-600">
              Owner
            </td>
            <td v-else-if="userInfo && userInfo.personal && userInfo.personal.email===person.email" class="text-xs text-primary-600">
              Editor
            </td>
            <td v-else>
              <div class="flex items-center">
                <v-popover
                  offset="4"
                  placement="bottom"
                  popoverClass="popover-sm"
                  popoverArrowClass="hidden"
                >
                  <div class="w-48 text-sm text-primary px-4 py-2.25 bg-primary-100 border border-primary-200 rounded flex items-center justify-between cursor-pointer">
                    <div class="text-sm">{{permissions[person.access]}}</div>
                    <ChevronDown class="w-3 h-3 dropdownCaret text-primary-500"/>
                  </div>

                  <template slot="popover">
                    <div class="w-48 p-1.5">
                      <div
                        v-for="(value,key) in Object.keys(permissions)"
                        :key="key"
                        :value="key"
                        @click="person.access=value;changeAccess(key, value)"
                        v-close-popover
                        class="relative pl-8 py-2.5 pr-2.5 mb-2 last:mb-0 text-sm text-primary cursor-pointer rounded hover:bg-primary-200"
                      >
                        {{permissions[value]}}
                        <CheckIcon v-if="person.access == value" class="h-5 w-5 absolute top-2.5 left-1.5"/>
                      </div>
                    </div>
                  </template>
                </v-popover>

                <div @click="revoke(key)" class="ml-3 cursor-pointer text-primary-700 hover:text-red">
                  <CloseIcon class="w-5 h-5" />
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import { humanLength, copyToClipboard } from '@/utilities/utility'
import store from '@/services/store'

import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
import CloseIcon from '@/components/base/icons/CloseCircle.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import CheckIcon from '@/components/base/icons/Check.vue'
import LinkIcon from '@/components/base/icons/Link.vue'

export default {
  components: {
    Modal,
    Button,
    ChevronRight,
    ChevronDown,
    CloseIcon,
    CheckIcon,
    LinkIcon
  },
  data () {
    return {
      copyLinkText: 'Copy Link',
      permissions: {
        read: 'Can view',
        edit: 'Can edit & comment',
        comment: 'Can comment'
      },
      publicPermissions: {
        off: 'Only people invited to this Doc',
        read: 'Anyone with the link can view',
        edit: 'Anyone with the link can edit & comment'
      },
      email: '',
      states: {
        200: 'Invited Succesfully!',
        201: 'Link settings changed successfully!',
        202: 'Updated Successfully!',
        500: 'Please add email',
        501: 'Email is invalid.',
        502: 'Already added',
        503: 'Unable to Invite. <a style="cursor:pointer;" onclick="window.Intercom(`showNewMessage`)">Contact Support<a>',
        504: 'Already invited',
        505: `Can't invite yourself`,
        506: '',
        507: 'Network Error',
        508: `Insufficient Balance. <a style="cursor:pointer;" @click="console.log('get more minute')">Get more minutes<a>`,
        509: 'Unable to Update. <a style="cursor:pointer;" onclick="window.Intercom(`showNewMessage`)">Contact Support<a>'
      },
      currentState: 0,
      active: {},
      countdownId: '',
      podId: '',
      ownerId: ''
    }
  },
  computed: {
    publicAccess: function() {
      if (store.state.editor.podcastInfo) {
        return store.state.editor.podcastInfo.publicAccess || 'read'
      }
      return 'false'
    },
    title () {
      if (store.state.editor.podcastInfo) {
        return store.state.editor.podcastInfo.title
      }
      return ''
    },
    userInfo () {
      return store.state.app.userInfo
    },
    team () {
      // in the share popup show the collaborators
      if (store.state.editor.podcastInfo && store.state.editor.podcastInfo.invited) {
        const ordered = {}
        Object.keys(store.state.editor.podcastInfo.invited).sort(function () {
          return -1
        }).forEach(function (key) {
          ordered[key] = store.state.editor.podcastInfo.invited[key]
        })
        return ordered
      }
      return {}
    },
    podcastInfo () {
      if (store.state.editor.podcastInfo) {
        return store.state.editor.podcastInfo
      }
      return {}
    }
  },
  watch: {
    currentState () {
      var vm = this
      if (vm.currentState !== 0) {
        vm.beginCountdown()
      }
    }
  },
  mounted () {
    let vm = this
    vm.ownerId = vm.$route.params.userId
    vm.podId = vm.$route.params.podId
  },
  methods: {
    setPublicAccess: function(val) {
      this.resetCurrentState()
      this.makePublic(val)
    },
    changeAccess (key, val) {
      console.log('log: changeAccess -> key, e', key, e)
      let vm = this
      vm.resetCurrentState()
      firebase.database().ref(`${vm.ownerId}/podcast-info/${vm.podId}/invited/${key}/access/`).set(val).then(function () {
        vm.currentState = 202
      }).catch(function (err) {
        console.log(err)
        vm.currentState = 509
      })
    },
    copyLink () {
      copyToClipboard(window.location.href)
      this.copyLinkText = 'Link Copied!'
      setTimeout(() => {
        this.copyLinkText = 'Copy Link'
      }, 1000)
    },
    makePublic (val) {
      let vm = this
      firebase.database().ref(`${vm.ownerId}/podcast-info/${vm.podId}/publicAccess`).set(val).then(function () {
        vm.currentState = 201
        // switch (val) {
        //   case 'off':
        //     vm.currentState = 201
        //     break
        //   case 'read':
        //     vm.currentState = 202
        //     break
        //   case 'edit':
        //     vm.currentState = 203
        // }
      }).catch(function (err) {
        console.log(err)
        vm.currentState = 509
      })
    },
    revoke (key) {
      let vm = this
      firebase.database().ref(`${vm.ownerId}/podcast-info/${vm.podId}/invited/${key}`).remove()
    },
    buyMinutes () {
      console.log('buy minutes')
      this.status = ''
      this.$emit('closeAdminModal')
      this.$emit('showBillingModal', 'Top Up')
    },
    beginCountdown () {
      var vm = this
      if (vm.countdownId !== '') {
        clearInterval(vm.countdownId)
      }
      vm.countdown = 10
      vm.countdownId = setInterval(reduceTime, 1000)
      function reduceTime () {
        vm.countdown -= 1
        if (vm.countdown === 0) {
          clearInterval(vm.countdownId)
          vm.resetCurrentState()
        }
      }
    },
    resetCurrentState () {
      let vm = this
      vm.currentState = 0
    },
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    },
    validateEmail (mail) {
      var input = document.createElement('input')
      input.type = 'email'
      input.required = true
      input.value = mail
      return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(mail)
    },
    alreadyInvited (mail) {
      var vm = this
      for (var i in vm.team) {
        // console.log(vm.team[i].email)
        if (vm.team[i].email === mail) {
          return true
        }
      }
      return false
    },
    ownEmail (mail) {
      var vm = this
      if (vm.userInfo.personal.email === mail) {
        return true
      }
      return false
    },
    inviteEmail () {
      var vm = this
      if (vm.email === '') {
        console.log('empty')
        vm.currentState = 500
        return
      } else if (!vm.validateEmail(vm.email)) {
        console.log('incorrect email')
        vm.currentState = 501
        return
      } else if (vm.alreadyInvited(vm.email)) {
        console.log('already invited')
        vm.currentState = 504
        return
      } else if (vm.ownEmail(vm.email)) {
        console.log(`can't invite yourself`)
        vm.currentState = 505
        return
      }
      var update = {}
      update['access'] = 'edit'
      update['email'] = vm.email
      update['invitedBy'] = vm.userInfo.personal.uid
      update['timestamp'] = Date().toString()
      firebase.database().ref(`${vm.ownerId}/podcast-info/${vm.podId}/invited`).push().set(update).then(function () {
        vm.currentState = 200
        vm.email = ''
      }).catch(function (err) {
        console.log(err)
        vm.currentState = 503
      })
      console.log('inviting')
    }
  }
}
</script>

<style lang="less" scoped>
.root {
  width: 560px;
}
</style>
